
import store from "@/modules/adaptedStorage";

import { defineComponent } from "vue";

import {
  IonPage,
  IonSelect,
  IonSelectOption,
  IonGrid,
  IonList,
  IonButton,
  IonRow,
  IonCol,
  IonContent,
  IonIcon,
  IonItem,
  IonInput,
  alertController,
} from "@ionic/vue";
import cyanRequest from "@/modules/cyanRequest";
import {
  ellipse,
  downloadOutline,
  cloudUploadOutline,
  checkmarkCircleOutline,
  attach,
  imagesOutline,
} from "ionicons/icons";
import { municipios } from "@/modules/cyanRegions";

import cyanGetPhoto from '@/modules/cyanGetPhoto';

export default defineComponent({
  name: "AgenteActas",
  components: {
    IonPage,
    IonSelect,
    IonSelectOption,
    IonContent,
    IonButton,
    IonItem,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonIcon,
    IonInput,
  },

  setup() {
    return {
      ellipse,
      downloadOutline,
      cloudUploadOutline,
      checkmarkCircleOutline,
      attach,
      imagesOutline,
    };
  },
  data() {
    return {
      convocatoria: "0",
      datosVotos: {},
      lightboxVisible: false,
    };
  },
  computed: {
    junta(): any {
      return (this as any).$route.params.junta || 0;
    },
    bootstrap(): any {
      return store.state.bootstrap[this.junta] || {};
    },
    centroJunta(): any {
      const b = this.bootstrap;
      if (!b || !b.centro || !b.junta) return "";
      return "JRV " + b.junta.numero + ", " + b.centro.nombre;
    },

    convocatorias(): any {
      return this.bootstrap.convocatorias || { "0": "Error" };
    },
    imagenesLightbox(): any {
      const result = [];
      if (this.estado.imagenes) {
        let counter = 1;
        for (const i of this.estado.imagenes) {
          result.push({
            src: i.url,
            title: "Imagen " + counter++ + " de " + this.estado.imagenes.length,
          });
        }
      }
      return result;
    },

    baseUrl(): string {
      const p = (this as any).$route.params;

      return "/agente/" + p.ambito + "/" + p.junta + "/";
    },
    datosConvocatorias() {
      return (this as any).bootstrap.datos_convocatorias || {};
    },
    datosConvocatoriaActual() {
      return (this as any).datosConvocatorias[(this as any).convocatoria] || {};
    },
    seed(): any {
      return (this as any).$route.params.ambito || "SV";
    },
    mapaPartidos(): any {
      if (
        !this.datosConvocatoriaActual ||
        !this.datosConvocatoriaActual.mapa_partidos
      )
        return {};

      return this.datosConvocatoriaActual.mapa_partidos;
    },
    partidos(): any {
      if (
        !this.datosConvocatoriaActual ||
        !this.datosConvocatoriaActual.orden_partidos
      )
        return [];

      const r = [];
      const dca = this.datosConvocatoriaActual;

      for (const x of dca.orden_partidos) {
        if (dca.mapa_partidos[x] && x != 50) r.push(dca.mapa_partidos[x]);
      }

      return r;
    },
    tieneVotoCruzado(): boolean {
      return !!(this as any).datosConvocatoriaActual.tiene_voto_fraccionado;
    },
    representantesVotoCruzado(): number {
      return (this as any).datosConvocatoriaActual.numero_representantes ?? 0;
    },
    candidatosVotoCruzado(): any {
      const r = [];

      for (let n = 1; n <= this.representantesVotoCruzado; n++) r.push(n);
      return r;
    },
    idNuevasIdeas(): string {
      return (this as any).datosConvocatoriaActual.id_ni ?? 0;
    },

    nombreNuevasIdeas(): string {
      const _t = this as any;
      const x = _t.mapaPartidos[_t.idNuevasIdeas];
      if (x && x.nombre) return x.nombre;
      return "???";
    },
    siglasNuevasIdeas(): string {
      const _t = this as any;
      const x = _t.mapaPartidos[_t.idNuevasIdeas];
      if (x && x.siglas) return x.siglas;
      return "???";
    },
    municipio(): string {
      if (this.seed in municipios) {
        const m = municipios[(this as any).seed];
        return m.nombre + ", " + m.nombrePadre;
      }
      return "";
    },

    estado(): any {
      if (
        !store.state.actas[this.junta] ||
        !store.state.actas[this.junta][this.convocatoria]
      )
        return {};

      return store.state.actas[this.junta][this.convocatoria];
    },
  },
  methods: {
    checkIsInput: function (event: any) {
      if (
        !event.target ||
        !event.target.tagName ||
        event.target.tagName != "INPUT"
      )
        return false;
      return true;
    },
    focusin: function (event: any) {
      if (!this.checkIsInput(event)) return;
      event.target.select();
    },
    focusout: function (event: any) {
      if (!this.checkIsInput(event)) return;

      // Validaciones
      // Primero, determinar si es un elemento fraccional o no

      const isDecimal = !!event.target.attributes.step;

      let v = event.target.value;
      const vo = v;

      // Si es fraccional, cambiar las comas por puntos y convertir en float.
      // Si no, convertir en entero

      v = isDecimal
        ? v.replace(",", ".").replace(/[^0-9.]/g, "")
        : v.replace(/[^0-9]/g, "");
      v = isDecimal ? parseFloat(v) : parseInt(v);
      if (v < 0 || !v) v = 0;
      if (isDecimal) v = v.toFixed(4);

      v += "";

      if (v != vo) event.target.value = v;
    },
    keypress: function (event: any) {
      if (!this.checkIsInput(event)) return;
      if (event.key !== "Enter") return;

      const listaInputs = document.getElementsByTagName("INPUT");
      for (let n = 1; n < listaInputs.length; n++) {
        if (listaInputs[n - 1] == event.target) {
          (listaInputs[n] as any).focus();
          break;
        }
      }
      return;
    },
    enviarActa: function () {
      const _t = this as any;

      new Promise((resolve) => {
        alertController
          .create({
            message:
              "Atención: una vez enviada el acta no podrá modificar los valores. Asegúrese de que son correctos. ¿Confirma el envío?",
            buttons: [
              "CANCELAR",
              {
                text: "CONFIRMAR",
                handler: resolve,
              },
            ],
          })
          .then((alert) => {
            alert.present();
          });
      })
        .then(function () {
          return cyanRequest(
            "agente/sendActa/" + _t.junta + "/" + _t.convocatoria,
            {
              isPost: true,
              needsAuth: true,
              params: _t.datosVotos[_t.convocatoria],
            }
          );
        })
        .then(function (d) {
          if (d.ok) {
            store.commit("storeActa", d);
          }
        });
    },
    confirmarActa: function () {
      const _t = this as any;

      new Promise((resolve) => {
        alertController
          .create({
            message:
              "Atención: va a indicar que el acta ha sido transmitida con éxito. ¿Es correcto?",
            buttons: [
              "CANCELAR",
              {
                text: "CONFIRMAR",
                handler: resolve,
              },
            ],
          })
          .then((alert) => {
            alert.present();
          });
      })
        .then(function () {
          return cyanRequest(
            "agente/marcarActa/" + _t.junta + "/" + _t.convocatoria,
            {
              isPost: true,
              needsAuth: true,
            }
          );
        })
        .then(function (d) {
          if (d.ok) {
            store.commit("storeActa", d);
          }
        });
    },
    verImagenes() {
      store.commit("setLightboxImages", this.imagenesLightbox);
      store.commit("setLightboxVisible", true);
    },
    async enviarFoto() {
      const image = await cyanGetPhoto();

      if (image === false) {
        return;
      }

      cyanRequest(
        "agente/addMedioActa/" + this.junta + "/" + this.convocatoria,
        {
          isPost: true,
          files: { fichero: image },
          isModal: true,
          needsAuth: true,
        }
      ).then(function (d) {
        if (d.ok) {
          store.commit("storeActa", d);
        }
      });
    },
    trasladarDatosConAdvertencia() {
      const _t = this as any;
      const dv = _t.datosVotos[_t.convocatoria];
      const s = _t.estado;

      if (!s.trasladable) return;

      if (!dv) return;

      let warn = false;

      for (const x in dv) {
        if (parseFloat(dv[x]) != 0) {
          warn = true;
          break;
        }
      }

      if (!warn) return _t.trasladarDatos();

      new Promise((resolve) => {
        alertController
          .create({
            message:
              "Atención: este acta ya tiene datos introducidos. ¿Seguro que desea sobreescribirlos con el resultado del conteo rápido?",
            buttons: [
              {
                text: "Sí",
                handler: resolve,
              },
              {
                text: "No",
                role: "cancel",
              },
            ],
          })
          .then((alert) => alert.present());
      }).then(_t.trasladarDatos);
    },
    trasladarDatos() {
      const _t = this as any;
      const s = _t.estado;
      const v = _t.datosVotos[_t.convocatoria];

      if (!s.trasladable) return;

      // Traslado de los datos

      for (const idx in s.estado) {
        if (typeof v["v_" + idx] !== "undefined") {
          v["v_" + idx] = s.estado[idx];
        }
      }

      if (!s.estado_vf) return;

      for (const idx in s.estado_vf.vf) {
        if (typeof v["vf_" + idx] !== "undefined") {
          v["vf_" + idx] = parseFloat(s.estado_vf.vf[idx])
            .toFixed(4)
            .replace(",", ".");
        }
      }

      for (const idx in s.estado_vf.marcas) {
        if (typeof v["marcas_" + idx] !== "undefined") {
          v["marcas_" + idx] = s.estado_vf.marcas[idx];
        }
      }
    },
  },
  mounted: function () {
    if (this.convocatoria !== "0") return;
    const k = Object.keys(this.convocatorias);
    if (k.length) this.convocatoria = k[0];
  },
  watch: {
    convocatorias(newVal) {
      if (newVal && this.convocatoria === "0") {
        this.convocatoria = Object.keys(newVal)[0];
      }
    },
    async convocatoria(newVal) {
      if (newVal == "0") return;
      // asegurarse de que tenemos el conteo

      if (typeof (this as any).datosVotos[newVal] !== "undefined") {
        const dv = (this as any).datosVotos[newVal];

        for (const x in dv) {
          if (parseFloat(dv[x]) != 0) {
            return;
          }
        }
      }

      // O bien todo son ceros, o bien no existe.
      // Asegurarse de que está completo

      const v = {} as any;

      (this as any).datosVotos[newVal] = v;

      const s = this.datosConvocatoriaActual;

      for (const p of s.orden_partidos) {
        if (p == 50) continue;
        v["v_" + p] = 0;
        if (!s.mapa_partidos[p].es_virtual && s.tiene_voto_fraccionado)
          v["vf_" + p] = "0.0000";
      }

      if (this.datosConvocatoriaActual.tiene_voto_fraccionado) {
        for (let n = 1; n <= s.numero_representantes; n++) {
          v["marcas_" + n] = 0;
        }
      }
    },
  },
});
